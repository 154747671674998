import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BlogsList from "../../../components/blogslist"

import "../../../css/resources.css"
import "../../../css/pageBanner.css"
export default class Blogs extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Blogs | Tata Studi"
          lang="en"
          description="Find resources to help your child in developing a good study habit and become an independent and confident learner | Tata Studi"
        />
        <h1 class="displayNone">Videos | Tata Studi</h1>
        <div className="pageBanner blogsPage" style={{ position: "initial" }} />
        <div className="blogs_banner">
          <span>Resources</span>
        </div>

        <div className="div_blogs">
          <span className="blogs_type_lbl font-samsung color-black">
            Our <span className="heading-blue">Latest Blogs</span>
          </span>
          <BlogsList />
        </div>

        <div
          className="row no-gutters"
          style={{ height: "150px", marginTop: "100px" }}
        >
          <div className="col-sm-8">
            <div className="div_view_plan">
              <span className="blog_pp_lbl">Check out our&nbsp;</span>
              <span className="blog_pp_lbl_last_word">Plans</span>
            </div>
          </div>

          <div className="col-sm-4 div_display_center">
            <Link className="" to="/plansandpricing/">
              <button className="blogs-card-button">View Plans</button>
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}
