import React from "react"
import { Link } from "gatsby"
import Blog1 from "../images/Blog1.jpg"
import Blog2 from "../images/Blog2.jpg"
import Blog3 from "../images/blogs/10ways.jpg"
import Blog4 from "../images/blogs/time-manage.jpg"
import Blog5 from "../images/blogs/parents.jpg"
import Blog6 from "../images/blogs/interleaving-1.jpg"
import Blog7 from "../images/blogs/spaced-practices-1.jpg"

var bloglist = [
  {
    imgSrc: Blog1,
    imgTitle: "Digitized Education",
    link: "/resources/blogs/digitized-education/",
    title: "The Digitization of Education and What It Spells for Your Child",
    info:
      "Technology has made sweeping changes to every industry around the world. Its ever-growing presence compounded by the wide-ranging nature of the Internet has enabled transformations that have benefited millions of people.",
  },

  {
    imgSrc: Blog2,
    imgTitle: "Science Of Learning",
    link: "/resources/blogs/science-of-learning/",
    title: "The Science of Learning And How It Can Help Your Child",
    info:
      "Neuroscientists are constantly peering into our brains. Fascinated by this organ, they discover something new and baffling almost every day, counter long-held ideas and reinforce the ones that have stood the test of time.",
  },
  {
    imgSrc: Blog3,
    imgTitle: "Studying Smarter to Learn Faster",
    link: "/resources/blogs/studying-smarter/",
    title: "Studying Smarter to Learn Faster: 10 Proven Ways for Students",
    info:
      "You may have heard the adage, ‘Work smarter, not harder’. The corporate world swears by it. But guess what? You can apply this to studying as well! Study smarter, not harder’ is a mantra that you should follow to get the most out of your study schedule.",
  },
  {
    imgSrc: Blog4,
    imgTitle: "Best time management tips for students",
    link: "/resources/blogs/time-management/",
    title: "Get your “me time” – Best time management tips for students",
    info:
      "We all have only 24 hours a day. It is about how well we manage our time, rather than how much time we have. In the current scenario of online learning, it is critical to managing time effectively.",
  },
  {
    imgSrc: Blog5,
    imgTitle:
      "Why Parents Involvement in Students Online Learning is Important",
    link: "/resources/blogs/parents-online-learning/",
    title: "Why Parents Involvement in Students Online Learning is Important",
    info:
      "It is a proven fact that students learn best when interacting with their teachers and peers. But the pandemic has forced the schools to move their classes online. This sudden change in the mode of teaching and learning has created many challenges for both parents and children.",
  },
  {
    imgSrc: Blog6,
    imgTitle: "The Art of Self Study",
    link: "/resources/blogs/art-of-interleaving/",
    title: "The Art of Interleaving and How it Benefits your child",
    info:
      "The weeks leading up to an exam can be chaotic. Innumerable textbooks, stacks of practice papers, and multi-coloured post-its to label important sections crowd your table. As a parent, it falls upon you to spin a schedule that will encompass every subject with adequate practice sessions.",
  },
  {
    imgSrc: Blog7,
    imgTitle:
      "Spaced Practices and How they Teach your Child to Learning Effectively",
    link: "/resources/blogs/spaced-practices/",
    title:
      "Spaced Practices and How they Teach your Child to Learning Effectively",
    info:
      "As parents, a common sight is watching your children seated at the dining table, scribbling away at their homework. Delightful, isn’t it? And hey! In most cases, they even nail it. But should we really consider learning a topic at school and then racing home to complete practice tests on the same topic as a marker of successful learning? We think not!",
  },
]

class BlogsList extends React.Component {
  render() {
    return (
      <div>
        <div className="container w-85 mt-4">
          <div className="row">
            {bloglist.map((item, key) => {
              return (
                <div className="col-md-6 mb-3" key={key}>
                  <img
                    src={item.imgSrc}
                    alt="Blog Image"
                    className="img-fluid rounded mb-3"
                    title={item.imgTitle}
                  />
                  <br />
                  <Link to={item.link}>
                    <span className="blog_title">{item.title}</span>
                  </Link>
                  <p className="blog_text mt-2">{item.info}</p>
                  <Link to={item.link}>
                    <span className="float-right">Show More...</span>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default BlogsList
